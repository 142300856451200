import React from 'react';
import "./index.css";
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger/src";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { mashie } from "./redux/reducers/mashie";

const reducers = combineReducers({mashie});

const middlewares = [thunk];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middlewares));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
