import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiGetMashieRequest = (code) => {
  return fetch(`${baseUrl}/mashie/${code}/submission`,
    {
      method: "GET",
      headers: buildHeaders()
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiMashieAnswersRequest = (payload, code) => {
  return fetch(`${baseUrl}/mashie/${code}/submission`,
    {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiMashieProgressRequest = (payload, code, answerId) => {
  return fetch(`${baseUrl}/mashie/${code}/submission/${answerId}/progress`,
    {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

