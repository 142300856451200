import './App.scss';
import { BrowserRouter, Route } from "react-router-dom";
import MashieCode from "./mashieCode/MashieCode/MashieCode";
import Questions from "./questions/Questions/Questions";
import Introduction from "./introduction/Introduction/Introduction";
import StartMashie from "./startMashie/StartMashie/StartMashie";
import Mashie from "./mashie/Mashie/Mashie";
import Results from "./results/Results/Results";
import BeforeScreens from './beforeScreens/BeforeScreens/BeforeScreens';
import AfterScreens from './AfterScreens/AfterScreens';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route path={"/code/:code"} exact component={ () => <MashieCode code={true}/> }/>
        <Route path={"/"} exact component={ () => <MashieCode/> }/>
        <Route path="/info" exact component={ () => <BeforeScreens /> }/>
        <Route path="/perguntas" exact component={ () => <Questions /> }/>
        <Route path="/introducao" exact component={ () => <Introduction /> }/>
        <Route path="/comecar" exact component={ () => <StartMashie /> }/>
        <Route path="/mashie" exact component={ () => <Mashie /> }/>
        <Route path="/resultado" exact component={ () => <Results /> }/>
        <Route path="/obrigado" exact component={ () => <AfterScreens /> }/>
      </BrowserRouter>
    </div>
  );
}

export default App;
