import {
  mashieAnswersRequestFailed,
  mashieAnswersRequestSucceeded, mashieProgressRequest, mashieProgressRequestFailed, mashieProgressRequestSucceeded,
  mashieRequest,
  mashieRequestFailed,
  mashieRequestSucceeded
} from "../action-creators/mashie";
import { apiGetMashieRequest, apiMashieAnswersRequest, apiMashieProgressRequest } from "../api/mashie";

const createMashieRequest = code => dispatch => {
  dispatch( mashieRequest() );
  return apiGetMashieRequest(code)
    .then( json => {
      dispatch( mashieRequestSucceeded( json ) );
      return Promise.resolve(json);
    } )
    .catch( response => {
        dispatch( mashieRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const getMashie = code => dispatch =>
  dispatch( createMashieRequest(code) );

const createMashieAnswersRequest = (payload, code) => dispatch => {
  dispatch( mashieRequest() );
  return apiMashieAnswersRequest(payload, code)
    .then( json => {
      dispatch( mashieAnswersRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( mashieAnswersRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const postMashieAnswers = (payload, code) => dispatch =>
  dispatch( createMashieAnswersRequest(payload, code) );

const createMashieProgressRequest = (payload, code, answerId) => dispatch => {
  dispatch( mashieProgressRequest() );
  return apiMashieProgressRequest(payload, code, answerId)
    .then( json => {
      dispatch( mashieProgressRequestSucceeded( json ) );
      return Promise.resolve();
    } )
    .catch( response => {
        dispatch( mashieProgressRequestFailed( response ) );
        return Promise.reject( response );
      }
    );
};

export const postMashieProgress = (payload, code, answerId) => dispatch =>
  dispatch( createMashieProgressRequest(payload, code, answerId) );

