import React from "react";
import "./Radio.scss";

const Radio = ({
  options,
  updateState,
  label,
  checked
}) => {
  const handleChange = e => {
    updateState(e.target.value);
  };

  return (
    <div className='Radio'>
      <p className="label">{label}</p>
      {options.map((item, index) => {
        return (
          <>
            <label key={index} className={`Radio ${checked ? "selected" : ""}`}>
              <div>
                <span className="radio">
                  <input name={item} type="radio" onChange={handleChange} value={item} checked={checked === item} />
                  <span className="control" />
                </span>
                <p>{item}</p>
              </div>
            </label>
          </>
        )
      })}
    </div>
  );
};

Radio.defaultProps = {
  disabled: false,
  label: "",
  valid: true
};

export default Radio;
