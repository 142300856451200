import {
  POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST,
  POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_FAILED,
  POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_SUCCEEDED,
  GET_MASHIE_INFO_REQUEST,
  GET_MASHIE_INFO_REQUEST_FAILED,
  GET_MASHIE_INFO_REQUEST_SUCCEEDED,
  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST,
  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_FAILED,
  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_SUCCEEDED,
} from "../constants/mashie";

export const mashieRequest = () => {
  return {
    type: GET_MASHIE_INFO_REQUEST
  }
};

export const mashieRequestSucceeded = (payload) => {
  return {
    type: GET_MASHIE_INFO_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const mashieRequestFailed = (json) => {
  return {
    type: GET_MASHIE_INFO_REQUEST_FAILED,
    error: json
  }
};

export const mashieAnswersRequest = () => {
  return {
    type: POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST
  }
};

export const mashieAnswersRequestSucceeded = (payload) => {
  return {
    type: POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const mashieAnswersRequestFailed = (json) => {
  return {
    type: POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_FAILED,
    error: json
  }
};

export const mashieProgressRequest = () => {
  return {
    type: POST_MASHIE_PROGRESS_SUBMISSION_REQUEST
  }
};

export const mashieProgressRequestSucceeded = (payload) => {
  return {
    type: POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_SUCCEEDED,
    data: payload
  }
};

export const mashieProgressRequestFailed = (json) => {
  return {
    type: POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_FAILED,
    error: json
  }
};

