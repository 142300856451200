import React, { useState, useEffect, useRef } from 'react';
import "./Mashie.scss";
import Logo from "../../global/Logo/Logo";
import Comparison from "../Comparison/Comparison";
import Button from "../../global/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { postMashieProgress } from "../../../redux/actions/mashie";
import { useHistory } from "react-router-dom";

const Mashie = () => {
  const mashie = useSelector(state => state.mashie);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState(false);
  const startTime = useRef(0);
  const dispatch = useDispatch();
  const hasNextScenario = mashie.scenario.nextScenario;
  const comparisons = hasNextScenario && mashie.scenario?.nextScenario[0].comparisons;
  const history = useHistory();
  const [stepSentence, setStepSentence] = useState(0);
  const [comparisonsList, setComparisonsList] = useState([]);

  const mashieTextList = {
    32: ["Escolha uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas",
      "Novamente, faça uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas",
      , "Siga fazendo suas escolhas",
      "Calma, está acabando. Faça uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas."],
    16: ["Escolha uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas", "Novamente, faça uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas",
      "Calma, está acabando. Faça uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas."],
    8: ["Escolha uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas", "Calma, está acabando. Faça uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas."],
    4: ["Escolha uma opção para cada par que mais lhe pareça coerente. Elas não são necessariamente opostas"]
  }

  useEffect(() => {
    startTime.current = performance.now();
    setComparisonsList(mashieTextList[mashie.data?.sentences.sentences.length / 2])
  }, [])

  window.addEventListener('popstate', (event) => {
    history.push('/')
    window.location.reload()
  });

  const handleSelection = (selection) => {
    const timePassed = Math.round((performance.now() - startTime.current) / 1000);
    setAnswers(prevState => [...prevState, { ...selection, timeItTookToSelect: timePassed }]);
    startTime.current = performance.now();
  }

  const handleMashieSubmit = () => {
    if (answers.length === comparisons.length) {
      dispatch(postMashieProgress({ comparisons: answers }, mashie.data.code, mashie.scenario.id));
      window.scrollTo(0, 0);
      setAnswers([]);
      setError(false)
      setStepSentence(stepSentence + 1);
    }
    else {
      setError(true)
    }
  };

  return (
    <section className="Mashie" style={{ minHeight: window.innerHeight }}>
      <progress value={answers?.length} max={comparisons?.length} aria-valuemin={0}
        aria-valuemax={comparisons?.length}
        aria-valuenow={answers?.length} />
      <div className="container">
        <Logo />
        <div className="mashie-info">
          <h2>{mashie.data.name}</h2>
          <p>{mashie.data.settings.scenarioPrompts.length >= 1
            ? mashie.data.settings.scenarioPrompts[stepSentence]
            : comparisonsList[stepSentence]}</p>
        </div>
        <div className="comparisons">
          {hasNextScenario
            ? comparisons.map((comparison, index) => {
              const sentences = mashie.data?.sentences.sentences;
              const sentence1 = sentences.filter((sentence) => sentence.id === comparison.leftSentenceId)[0];
              const sentence2 = sentences.filter((sentence) => sentence.id === comparison.rightSentenceId)[0];
              return <Comparison key={index} sentence1={sentence1} sentence2={sentence2}
                inactive={index < answers.length || index > answers.length || answers.length === comparisons.length}
                comparisonId={comparison.id} setSelectedSentence={handleSelection}
                selectedSentence={answers[index]?.selectedSentenceId} />
            }
            )
            : mashie.scenario.status === "FINISHED" && history.push("/resultado")}
        </div>
        <p className={`error-message ${error ? "active" : ""}`}>Responda todas as perguntas antes de prosseguir</p>
        <Button onClick={handleMashieSubmit}>continuar</Button>
      </div>
    </section>
  );
};

export default Mashie;
