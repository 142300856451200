import React from 'react';
import "./Input.scss";
import { replaceWithLineBreaks } from '../../utils/formating';

const Input = ({type, updateState, value, label, errorMessage, displayError}) => {
  const handleChange = (e) => {
    updateState(e.target.value)
  }

  return (
    <div className="Input">
      <label dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(label)}}></label>
      <p className={ `error-message ${displayError ? "displayError" : ""}` }>{errorMessage}</p>
      <input type={type} onChange={(e) => handleChange(e)} value={value}/>
    </div>
  );
};

export default Input;
