export const baseUrl = process.env.REACT_APP_MASHIE_API;

export const buildHeaders = () => {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };

  return headers;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getResponseOrThrow = async response => {
  if (response.ok) {
    return isJsonString() ? await response : response.json();
  } else {
    throw isJsonString() ?  await response : response.json();
  }
};
