import React from 'react';
import "./Introduction.scss";
import Logo from "../../global/Logo/Logo";
import { useHistory } from "react-router-dom";
import Button from "../../global/Button/Button";
import { useSelector } from 'react-redux';
import { replaceWithLineBreaks } from '../../utils/formating';
import LogoMashie from "../../../assets/images/logo-white.png";

const Introduction = () => {
  const history = useHistory();
  const mashie = useSelector(state => state.mashie);

  const renderIntroduction = () => {
    if (mashie.data.settings.thankyouMessage) {
      return (
        <>
          <img className='logo-mashie' src={mashie.data?.settings?.logo} />
          <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(mashie.data.settings.thankyouMessage) }}></p>
        </>
      )
    } else {
      return (
        <>
          <Logo />
          <p>Mashie é uma ferramenta de pesquisa para identificação de aprendizagem.</p>
          <p>Em linhas gerais, é um conjunto de premissas com frases em pares, os quais devem ser
            avaliados de acordo com o que faça mais sentido para você.</p>
          <p>Conforme as escolhas realizadas, as frases vencedoras são combinadas
            com outras e devem ser analisadas e selecionadas novamente, até obter-se  as 4 opções mais significativas.</p>
        </>
      )
    }
  }

  return (
    <section className="Introduction">
      <div className="container">
        {renderIntroduction()}
        <Button onClick={() => history.push("/comecar")}>
          continuar
        </Button>
      </div>
      <img src={LogoMashie} className="logo-footer" ></img>
    </section>
  );
};

export default Introduction;
