import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMashie } from "../../../redux/actions/mashie";
import Button from "../../global/Button/Button";
import Input from "../../global/Input/Input";
import Loading from "../../global/Loading/Loading";
import Logo from "../../global/Logo/Logo";
import "./MashieCode.scss";

const MashieCode = ({ code }) => {
  const [mashieCode, setMashieCode] = useState("");
  const mashie = useSelector(state => state.mashie);
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = window.location.pathname
  const regexWhiteSpace = /\s/g;

  const handleCodeSubmit = (code) => {
    dispatch(getMashie(mashieCode || code)).then((response) => {
      if (response.beforeScreens?.length > 0) {
        history.push("/info")
      } else {
        response.settings.questions.length > 0 ?
          history.push("/perguntas") :
          history.push("/introducao")
      }
    });
  }

  useEffect(() => {
    if (code) {
      handleCodeSubmit(pathname.replace(regexWhiteSpace, '').split('/')[2])
    }
  }, [])

  return (
    <section className="MashieCode" style={{ height: window.innerHeight }}>
      <Loading visible={mashie?.isFetching} message="Carregando, aguarde!" />
      <div className="container">
        <Logo />
        <h2>BEM VINDO AO MASHIE</h2>
        <form onSubmit={e => e.preventDefault()}>
          <Input
            type="text"
            updateState={setMashieCode}
            value={mashieCode}
            label="digite seu código abaixo:"
          />
          <Button
            onClick={handleCodeSubmit}
          >
            começar
          </Button>
        </form>
      </div>
    </section>
  );
};

export default MashieCode;
