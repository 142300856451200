import React from 'react';
import "./Logo.scss";
import logoSrc from "../../../assets/images/logo-white.png";

const Logo = () => {

  return (
    <img className="Logo" src={ logoSrc } alt="Logo Mashie"/>
  );
};

export default Logo;
