import {
  POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST,
  POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_FAILED,
  POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_SUCCEEDED,
  GET_MASHIE_INFO_REQUEST,
  GET_MASHIE_INFO_REQUEST_FAILED,
  GET_MASHIE_INFO_REQUEST_SUCCEEDED,
  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST,
  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_FAILED,
  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_SUCCEEDED,
} from "../constants/mashie";

export const mashie = (
  state = {
    isFetching: false,
    scenario: {},
    data: [],
    error: {}
  }, action) => {
  let newState = {...state};

  switch (action.type) {
    case GET_MASHIE_INFO_REQUEST:
    case POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST:
    case POST_MASHIE_PROGRESS_SUBMISSION_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_MASHIE_INFO_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_MASHIE_INFO_REQUEST_FAILED:
    case POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    case  POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.scenario = {};
      return newState;

    case POST_MASHIE_REQUIREMENTS_ANSWERS_REQUEST_SUCCEEDED:
    case POST_MASHIE_PROGRESS_SUBMISSION_REQUEST_SUCCEEDED:
      newState.scenario = action.data;
      newState.isFetching = false;
      return newState;

    default:
      return newState;
  }
};
