import React, { useState, useEffect } from 'react';
import "./BeforeScreens.scss";
import { useHistory } from 'react-router-dom';
import Button from "../../global/Button/Button";
import LogoMashie from "../../../assets/images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { replaceWithLineBreaks } from '../../utils/formating';

const BeforeScreens = () => {
  const mashie = useSelector(state => state.mashie);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const infos = mashie.data?.beforeScreens;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let updateColor = document.documentElement;
    mashie.data.settings?.themeColor &&
      updateColor.style.setProperty('--primary-color', mashie.data.settings?.themeColor);
  }, [mashie]);

  const handleSubmit = () => {
    infos.length > currentQuestion + 1
      ? setCurrentQuestion(prevState => prevState + 1)
      : history.push("/perguntas")
  };
  console.log(infos[currentQuestion])

  return (
    <section className="BeforeScreens" >
      <progress value={currentQuestion + 1} max={infos?.length} aria-valuemin={0}
        aria-valuemax={infos?.length}
        aria-valuenow={currentQuestion + 1} />
      <div className="container">
        <img className='logo-mashie' src={mashie.data?.settings?.logo} />
        {infos?.[currentQuestion]?.widgets.map((item, key) => {
          if (item.type === "VIDEO") {
            return (<>
              <video width="80%" height="100%" autoPlay controls>
                <source src={item.metadata.text} type="video/webm" />
              </video>
            </>)
          }
          if (item.type === "TEXT") {
            return (<>
              <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(item.metadata.text) }}></p>
            </>)
          }
        })}
        <div className="buttons">
          <Button onClick={() => handleSubmit()}>
            Continuar
          </Button>
        </div>
      </div>
      <img src={LogoMashie} className="logo-footer" ></img>
    </section>
  );
};

export default BeforeScreens;
