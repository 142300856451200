import React from 'react';
import "./Button.scss";

const Button = ({children, onClick, className, outline }) => {
  return (
    <button className={ `Button ${className ? className : ""} ${outline ? "outline" : ""}` } onClick={onClick}>
      { children }
    </button>
  );
};

export default Button;
