import React from 'react';
import "./Comparison.scss";
import Sentence from "../Sentence/Sentence";

const Comparison = ({inactive, sentence1, sentence2, comparisonId, setSelectedSentence, selectedSentence}) => {
  const handleSentenceSelection = (sentenceId) => {
    setSelectedSentence({
      id: comparisonId,
      selectedSentenceId: sentenceId
    }) ;
  };

  return (
    <div className={ `Comparison ${inactive ? "inactive" : "" }` }>
      <Sentence sentence={ sentence1 } updateState={handleSentenceSelection} selected={selectedSentence === sentence1?.id}/>
      <p>ou</p>
      <Sentence sentence={ sentence2 } updateState={handleSentenceSelection} selected={selectedSentence === sentence2?.id}/>
    </div>
  );
};

export default Comparison;
