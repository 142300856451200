import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import LogoMashie from "../../assets/images/logo-white.png";
import Button from "../global/Button/Button";
import { replaceWithLineBreaks } from '../utils/formating';
import "./AfterScreens.scss";

const AfterScreens = () => {
  const mashie = useSelector(state => state.mashie);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [afterStep, setAfterStep] = useState("Continuar");

  const infos = mashie.data?.afterScreens;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    infos.length === currentQuestion + 1 && setAfterStep("Finalizar")
  }, [currentQuestion]);

  const handleSubmit = () => {
    infos.length > currentQuestion + 1
      ? setCurrentQuestion(prevState => prevState + 1)
      : history.push("/")
  };

  return (
    <section className="AfterScreens" >
      <progress value={currentQuestion + 1} max={infos?.length} aria-valuemin={0}
        aria-valuemax={infos?.length}
        aria-valuenow={currentQuestion + 1} />
      <div className="container">
        <img className='logo-mashie' src={mashie.data?.settings?.logo} />
        {infos?.[currentQuestion]?.widgets.map((item, key) => {
          if (item.type === "VIDEO") {
            return (<>
              <video width="80%" height="100%" autoPlay controls>
                <source src={item.metadata.text} type="video/webm" />
              </video>
            </>)
          }
          if (item.type === "TEXT") {
            return (<>
              <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(item.metadata.text) }}></p>
            </>)
          }
        })}
        <div className="buttons">
          {afterStep !== "Finalizar" && <Button onClick={() => handleSubmit()}>
            {afterStep}
          </Button>}
        </div>
      </div>
      <img src={LogoMashie} className="logo-footer" ></img>
    </section>
  );
};

export default AfterScreens;
