import React, { useState, useEffect } from 'react';
import "./Questions.scss";
import { useHistory } from 'react-router-dom';
import Button from "../../global/Button/Button";
import LogoMashie from "../../../assets/images/logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import Question from "../Question/Question";
import { postMashieAnswers } from "../../../redux/actions/mashie";
import { replaceWithLineBreaks } from '../../utils/formating';

const Questions = () => {
  const mashie = useSelector(state => state.mashie);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [error, setError] = useState(false);
  const [emptyError, setEmptyError] = useState(false);

  const questions = mashie.data?.settings?.questions;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let updateColor = document.documentElement;
    mashie.data.settings?.themeColor &&
      updateColor.style.setProperty('--primary-color', mashie.data.settings?.themeColor);
  }, [mashie]);

  useEffect(() => {
    if (questions[currentQuestion].validationRegex) {
      setError(questions[currentQuestion].validationRegex.test(currentAnswer));
    }
    const answer = { id: questions[currentQuestion].id, answer: currentAnswer };
    setAnswers(prevState => {
      const newState = [...prevState];
      newState[currentQuestion] = answer;
      return newState;
    });
  }, [currentAnswer, questions, currentQuestion]);

  const handleSubmit = () => {
    if (!answers[currentQuestion]?.answer) {
      setEmptyError(true)
    } else {
      questions.length > currentQuestion + 1
        ? setCurrentQuestion(prevState => prevState + 1)
        : dispatch(postMashieAnswers({ questions: answers }, mashie.data.code)).then(() => history.push("/introducao"));
      setEmptyError(false)
    }
    setCurrentAnswer("");
  };

  return (
    <section className="Questions" >
      <progress value={answers?.length} max={questions?.length} aria-valuemin={0}
        aria-valuemax={questions?.length}
        aria-valuenow={answers?.length} />
      <div className="container">
        {questions &&
          <>
            <img className='logo-mashie' src={mashie.data?.settings?.logo} />
            <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(mashie?.data?.description) }}></p>
            <Question question={questions[currentQuestion]} answer={answers[currentQuestion]?.answer}
              setAnswer={setCurrentAnswer} currentQuestion={currentQuestion} />
          </>
        }
        <div className="buttons">
          {emptyError && <p>Preencha a pergunta corretamente!</p>}
          <Button onClick={() => handleSubmit()}>
            Continuar
          </Button>
        </div>
      </div>
      <img src={LogoMashie} className="logo-footer" ></img>
    </section>
  );
};

export default Questions;
