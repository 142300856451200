import React from "react";
import "./Loading.scss";
import PropTypes from "prop-types";

const Loading = ({visible, message}) => {

  return (
    <section className={`Loading ${visible ? "visible" : null} `}>
      <div>
        <div className={"container"}>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
        {message && <p>{message}</p>}
      </div>
    </section>
  );
};

Loading.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default Loading;
