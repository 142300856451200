import React from 'react';
import "./Sentence.scss";

const Sentence = ({sentence, selected, updateState}) => {
  const handleClick = () => {
    updateState(sentence.id);
  }

  return (
    <div className={ `Sentence ${selected ? "selected" : ""}` } onClick={handleClick}>
        <p>{sentence?.name}</p>
    </div>
  );
};

export default Sentence;
