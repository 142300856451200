import React from 'react';
import "./Results.scss";
import { useSelector } from "react-redux";
import Sentence from "../../mashie/Sentence/Sentence";
import Logo from "../../global/Logo/Logo";
import Button from "../../global/Button/Button";
import { useHistory } from "react-router-dom";

const Results = () => {
  const mashie = useSelector( state => state.mashie );
  const history = useHistory();
  const scenarios = mashie.scenario.scenarios;

  return (
    <section className="Results">
      <div className="container" >
        <Logo/>
        <article className="mashie-info">
          <h2>{ mashie.data.name }</h2>
          <p>Acabou! E esse é o seu resultado, logo, para você essas afirmações são muito 
            importantes para o seu processo de aprendizagem</p>
        </article>
        <h2>RESULTADO</h2>
        <div className="sentences">
          { scenarios[scenarios?.length - 1]?.comparisons.map( ( comparison, index ) => {
            const sentence = mashie.data?.sentences?.sentences?.filter( ( sentence ) => sentence.id === comparison.selectedSentenceId )[0];
            return <Sentence key={index} selected sentence={ sentence } updateState={ () => {
            } }/>
          } ) }
        </div>
        <Button onClick={() => history.push("/obrigado")}>continuar</Button>
      </div>
    </section>
  );
};

export default Results;
