import React, { useEffect } from 'react';
import "./StartMashie.scss";
import Logo from "../../global/Logo/Logo";
import { useHistory } from "react-router-dom";
import Button from "../../global/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { postMashieAnswers } from "../../../redux/actions/mashie";
import LogoMashie from "../../../assets/images/logo-white.png";

const StartMashie = () => {
  const history = useHistory();
  const mashie = useSelector(state => state.mashie);
  const dispatch = useDispatch();

  useEffect(() => {
    Object.keys(mashie.scenario).length === 0 && dispatch(postMashieAnswers({ questions: [] }, mashie.data.code));
  }, [dispatch, mashie.scenario, mashie.data.code]);


  const mashieTime = () => {
    let time = mashie.data?.metrics?.estimatedDuration;
    if (time > 60) {
      time = Math.round(time / 60);
      return `${time} minutos`
    }
    return `${time} segundos`;
  }

  return (
    <section className="StartMashie" style={{ height: window.innerHeight }}>
      <div className="container">
        {mashie.data?.settings?.logo ? <img className='logo-mashie' src={mashie.data?.settings?.logo} />
          : <Logo />}
        <article>
          <p>Muito bem, iremos começar o teste</p>
          <p>Ele leva em torno de {mashieTime()}</p>
        </article>
        <Button onClick={() => history.push("/mashie")}>
          começar
        </Button>
      </div>
      <img src={LogoMashie} className="logo-footer" ></img>
    </section>
  );
};

export default StartMashie;
