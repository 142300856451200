import React, { useEffect } from 'react';
import "./Question.scss";
import Input from "../../global/Input/Input";
import Select from "../../global/Select/Select";
import Radio from '../../global/Radio/Radio';

const Question = ({ question, setAnswer, answer, currentQuestion }) => {
  const type = question?.type;
  const typeEnums = {
    INTEGER: "number",
    CPF: "number",
    PHONE: "phone",
    STRING: "text",
    DATE: "date",
    EMAIL: "email",
    RADIO: "radio"
  }

  useEffect(() => {
    const newspaper = document.getElementById("Question")
    newspaper.animate([{ opacity: 0 }, { opacity: 1 }], 1200);
  }, [currentQuestion]);

  const renderQuestion = () => {
    if (type === "LIST") {
      return <Select label={question.prompt} defaultOption="Selecione uma opção" options={question.values} updateState={setAnswer}
        displayError={false} value={answer} />
    } else if (type === "RADIO") {
      return <Radio
        checked={answer}
        options={question.values}
        updateState={setAnswer}
        label={question.prompt}
      />
    } else {
      return <Input label={question?.prompt} type={typeEnums[type]} value={answer} updateState={setAnswer}
        displayError={false}
        errorMessage="" />
    }
  }

  return (
    <div id='Question' className="Question">
      {renderQuestion()}
    </div>
  );
};

export default Question;
